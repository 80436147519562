import { knowledgeServices } from "@common/data/content/service-cards";
import Layout from "@components/layout";
import SEO from "@components/seo";
import ServiceCardList from "@components/UI-Elements/Cards/ServiceCardList";
import React from "react";

const title = "Wissen";
const description = "Hier findest du nützliche Informationen über Bitcoin und Kryptowährungen.";
const CryptoLinks: React.FC = () => {
  return (
    <Layout prose>
      <SEO title={title} description={description} />
      <ServiceCardList
        title={title}
        titleProps={{ level: 1 }}
        subtitle={description}
        sectionProps={{ className: "py-0" }}
        services={knowledgeServices}
      />
    </Layout>
  );
};

export default CryptoLinks;
